import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseUrl: '/upload/',
    language: 'en',
    bodyClick: false,
    clearModal: false,
    showKefuModal: false, // 聊天弹框
    kefuUID: '', // 聊天弹框
    webSocket: '',
    logo: '',
    app_promotion: '',
    openCart: false, // 添加购物车弹框
    isLogin: false,
    touchable: false, // 轮播是否能滑动
    showMenu: true, // seller后台菜单显示
    userType: '', //custom: 客户; seller：商家
    userInfo: {},
    shopInfo: {},
    moneySymbol: '$', // 默认货币符号
    moneyRate: 1, // 默认汇率
    goodsInfo: {},
    categoryList: [], //分类
    cartList: [], // 购物车列表
    wishList: [], // 愿望列表
    noticeList: [], // 通知
    cartCount: 0, // 购物车产品数量
    messageCount: 0, // 未读数
    withdraw_type: '', // 未读数
    task_switch: '1', // 刷单任务
    country: [
      "Uzbekistan",
      "Austria",
      "Sierra Leone",
      "Cocos (Keeling) Islands",
      "Mauritania",
      "Israel",
      "Moldova",
      "Fiji",
      "Serbia",
      "Cameroon",
      "Bahamas",
      "Nauru",
      "Czechia",
      "Egypt",
      "Myanmar",
      "Netherlands",
      "Uruguay",
      "Northern Mariana Islands",
      "Central African Republic",
      "Saint Helena, Ascension and Tristan da Cunha",
      "Equatorial Guinea",
      "Colombia",
      "Kyrgyzstan",
      "Spain",
      "Malawi",
      "Hungary",
      "Monaco",
      "Cape Verde",
      "South Africa",
      "Denmark",
      "Guadeloupe",
      "Ireland",
      "Malta",
      "Wallis and Futuna",
      "Suriname",
      "Vatican City",
      "Mali",
      "Tonga",
      "San Marino",
      "Saint Barthélemy",
      "Gibraltar",
      "Canada",
      "Benin",
      "Guam",
      "North Korea",
      "Tuvalu",
      "Panama",
      "Rwanda",
      "Republic of the Congo",
      "Jamaica",
      "Bahrain",
      "Sint Maarten",
      "Turks and Caicos Islands",
      "Pakistan",
      "Kazakhstan",
      "Laos",
      "Trinidad and Tobago",
      "Montenegro",
      "Niue",
      "Liberia",
      "Grenada",
      "Papua New Guinea",
      "Chad",
      "Chile",
      "Puerto Rico",
      "Saudi Arabia",
      "Norway",
      "Gambia",
      "Philippines",
      "Isle of Man",
      "Portugal",
      "Honduras",
      "Cyprus",
      "Anguilla",
      "Heard Island and McDonald Islands",
      "Togo",
      "Lebanon",
      "Morocco",
      "Estonia",
      "Faroe Islands",
      "Argentina",
      "Gabon",
      "Namibia",
      "Vietnam",
      "Greece",
      "British Virgin Islands",
      "Mozambique",
      "Norfolk Island",
      "South Georgia",
      "Armenia",
      "Kenya",
      "Bhutan",
      "United Arab Emirates",
      "Cook Islands",
      "Ethiopia",
      "Singapore",
      "Peru",
      "Palestine",
      "Samoa",
      "South Sudan",
      "Andorra",
      "Saint Martin",
      "Eswatini",
      "Tajikistan",
      "Zambia",
      "United States",
      "Burundi",
      "Japan",
      "Curaçao",
      "Uganda",
      "Mongolia",
      "Nigeria",
      "Guatemala",
      "Jersey",
      "Costa Rica",
      "Yemen",
      "Greenland",
      "Madagascar",
      "Algeria",
      "Belgium",
      "Sri Lanka",
      "Finland",
      "Bermuda",
      "North Macedonia",
      "Saint Vincent and the Grenadines",
      "Niger",
      "British Indian Ocean Territory",
      "Latvia",
      "Nepal",
      "Ivory Coast",
      "Liechtenstein",
      "DR Congo",
      "Belize",
      "Qatar",
      "Tokelau",
      "Indonesia",
      "French Polynesia",
      "Lesotho",
      "Poland",
      "Palau",
      "Guernsey",
      "Antigua and Barbuda",
      "Saint Pierre and Miquelon",
      "Kosovo",
      "Western Sahara",
      "Luxembourg",
      "Taiwan",
      "Hong Kong",
      "Turkmenistan",
      "Russia",
      "Azerbaijan",
      "Ecuador",
      "Cambodia",
      "Mayotte",
      "Botswana",
      "Croatia",
      "Saint Lucia",
      "Paraguay",
      "Bolivia",
      "Maldives",
      "American Samoa",
      "Iceland",
      "Slovakia",
      "French Southern and Antarctic Lands",
      "Brunei",
      "Saint Kitts and Nevis",
      "Afghanistan",
      "Ghana",
      "Kuwait",
      "Svalbard and Jan Mayen",
      "Bangladesh",
      "Guyana",
      "Kiribati",
      "Barbados",
      "Albania",
      "Pitcairn Islands",
      "Burkina Faso",
      "Macau",
      "Seychelles",
      "Switzerland",
      "South Korea",
      "United States Virgin Islands",
      "Tunisia",
      "Bouvet Island",
      "Iran",
      "Jordan",
      "Réunion",
      "Turkey",
      "Tanzania",
      "Ukraine",
      "Mauritius",
      "Somalia",
      "Guinea",
      "French Guiana",
      "Marshall Islands",
      "Solomon Islands",
      "United States Minor Outlying Islands",
      "Lithuania",
      "Slovenia",
      "Nicaragua",
      "Dominican Republic",
      "Iraq",
      "El Salvador",
      "Venezuela",
      "Zimbabwe",
      "Sweden",
      "Thailand",
      "Timor-Leste",
      "Bosnia and Herzegovina",
      "Guinea-Bissau",
      "Montserrat",
      "Dominica",
      "Falkland Islands",
      "Caribbean Netherlands",
      "Djibouti",
      "Antarctica",
      "Haiti",
      "Comoros",
      "Italy",
      "Belarus",
      "Cayman Islands",
      "France",
      "Senegal",
      "China",
      "Sudan",
      "Oman",
      "United Kingdom",
      "Mexico",
      "Australia",
      "Cuba",
      "Eritrea",
      "Bulgaria",
      "Romania",
      "Micronesia",
      "Vanuatu",
      "Syria",
      "São Tomé and Príncipe",
      "Germany",
      "New Zealand",
      "Angola",
      "Christmas Island",
      "Aruba",
      "India",
      "Malaysia",
      "Georgia",
      "New Caledonia",
      "Libya",
      "Åland Islands",
      "Martinique",
      "Brazil"
    ],
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
